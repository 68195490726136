.container-splash {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: splashIconIn 2s ease-in-out both;
}

.splash-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.splash-icon path {
  fill: var(--grey);
}

.splash-text {
  font-size: 40px;
  color: var(--grey);
  font-family: "Fuji Quake Zone", "sans-serif";
  animation: splashIconIn 2s ease-in-out both;
}

.splash-tm{
  position: absolute;
  right: -25px;
  top: 0;
  color: var(--grey);
  font-weight: bold;
  font-size: 14px;
}

@keyframes splashIconIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
