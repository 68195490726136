.container-not-found-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "DIN Next LT Pro", "sans-serif";
  font-size: 25px;
}

.not-found-svg {
  width: 500px;
  max-width: 80%;
  height: 200px;
  margin: 50px 0;
}
