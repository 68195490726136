.buttons {
  display: flex;
  margin-left: 30px;
  flex-wrap: wrap;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  font-size: 15px;
  height: 60px;
  color: var(--white);
  transition: ease 0.2s;
  font-weight: 700;
}

.nav-button:hover,
.nav-button-select {
  cursor: pointer;
  color: var(--white-hover);
}

.nav-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.nav-button-select .nav-icon g,
.nav-button:hover .nav-icon g {
  fill: var(--orange);
}

@media (max-width: 1000px) {
  .buttons {
    display: none;
  }
}
