.content-nav {
  background-color: var(--black);
  color: var(--white);
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 2;
  transition: ease-in 0.3s;
  box-sizing: border-box;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-container-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.navbar-logo-text {
  color: var(--white);
  font-family: "Fuji Quake Zone", "sans-serif";
  font-weight: 900;
  font-size: 26px;
}

.navbar-logo-tm {
  color: var(--white);
  font-weight: bold;
  font-size: 9px;
  position: absolute;
  right: -18px;
  top: 0;
}

.navbar-logo-drop {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.navbar-logo-drop path {
  fill: var(--white);
}

.container-nav-bag {
  position: relative;
  cursor: pointer;
}

.nav-bag {
  width: 20px;
}

.nav-bag path {
  fill: var(--white);
}

.bag-quantity-items {
  position: absolute;
  color: var(--white);
  top: -5px;
  right: -9px;
  background-color: var(--red);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .content-nav {
    height: 46px;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
