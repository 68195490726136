.container-modal-filter-products {
  display: none;
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 350px;
  max-width: 75vw;
  background-color: var(--light-grey);
  z-index: 50;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0;
  overflow: auto;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.modal-filter-products-section {
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #e0ded7;
  padding: 9px 33px 10px 20px;
  background-color: #fcfcfa;
  color: var(--black);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.modal-filter-products-section-close {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  justify-content: space-between;
  background-color: inherit;
  padding: 18px 40px 20px 20px;
}

.modal-filer-products-title {
  font-size: 23px;
  font-weight: 800;
  font-family: "DIN Next LT Pro", "sans-serif";
}

.modal-filter-products-open {
  animation: modal-filter-products-open 0.5s ease-in-out both;
}

@keyframes modal-filter-products-open {
  0% {
    opacity: 0;
    right: -100%;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

.modal-filter-products-close {
  animation: modal-filter-products-close 0.5s ease-in-out both;
}

@keyframes modal-filter-products-close {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: -100%;
  }
}

@media (max-width: 1000px) {
  .container-modal-filter-products {
    display: block;
  }
}
