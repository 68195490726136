.input-product {
  text-transform: inherit !important;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

.product-container-image {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.button-add-image-product {
  width: 1000px;
  height: 1200px;
  max-width: 100%;
  max-height: 80vh;
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--grey);
}

.product-admin-main-image {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
  background-color: var(--white);
  min-height: 30px;
}

.main-image-select {
  background-color: var(--grey);
  color: var(--white);
  border: none;
}

.product-info-rectangule {
  border-radius: 5px;
  background-color: var(--light-grey);
  padding: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  margin: 3px;
}

.product-info-rectangule:hover,
.product-info-rectangule-select {
  background-color: var(--grey);
  color: var(--white);
  cursor: pointer;
}

.product-container-thumbnails-image {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-height: 80vh;
}

.product-container-thumbnails {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.product-image-thumbnails {
  width: 60px;
  height: 75px;
  border: 1px solid transparent;
  padding: 1px;
  box-sizing: border-box;
}

.product-image-thumbnails:hover {
  border: 1px solid var(--grey);
  cursor: pointer;
}

.product-thumbnails-add {
  box-sizing: border-box;
  width: 60px;
  height: 75px;
  border-radius: 10px;
  margin: 0;
}

.image-thumbnail-delete {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--grey);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .product-container-thumbnails-image {
    flex-direction: column;
    max-height: 100%;
  }
  .product-container-thumbnails {
    flex-direction: row;
  }
}
