.container-filters-products {
  width: 228px;
  box-sizing: border-box;
  padding: 0 5px;
  margin-right: 40px;
}

.filter-products-title {
  font-family: "DIN Next LT Pro", "sans-serif";
  color: var(--grey);
  text-align: start;
  font-size: 20px;
  text-transform: uppercase;
}

.filter-products-title-filters {
  width: 100%;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1000px) {
  .container-filters-products {
    width: 100%;
  }
  .filter-products-title-filters {
    margin-bottom: 20px;
  }
}

.icon-filter {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
