.container-app {
  width: 100vw;
  color: var(--black);
}

.container-content-app {
  padding-top: 28px;
  padding-bottom: 28px;
  position: relative;
  min-height: calc(100vh - 260px);
}

.max-width {
  width: 1568px;
  max-width: 95%;
  margin: 0 auto;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.appear-window {
  animation: appear-window 1s ease-in-out both;
}

@keyframes appear-window {
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.disappear-window {
  animation: disappear-window 1s ease-in-out both;
}

@keyframes disappear-window {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(500px);
    opacity: 0;
  }
}

.button-gradient-blue {
  background-color: #1477b2;
  background-image: linear-gradient(153.43deg, #1b99b9, #0a5992);
  color: var(--white);
  min-height: 50px;
  width: 100%;
  border: none;
  font-family: "DIN Next LT Pro", "sans-serif";
  box-sizing: border-box;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

.button-gradient-blue:hover {
  background-color: var(--black);
  background-image: none;
}

.react-toast-notifications__container {
  margin-top: 40px;
}

.container-error {
  text-align: center;
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
}

.error-title {
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 15px;
  font-family: "DIN Next LT Pro", "sans-serif";
}

.error-message {
  font-weight: 600;
}
