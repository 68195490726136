.container-panel-admin {
  margin: 0 auto;
  width: 500px;
  max-width: 90vw;
}

.panel-admin-title {
  font-family: "DIN Next LT Pro", "sans-serif";
  font-size: 30px;
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
}

.container-panel-admin > .button{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}
