.container-modal-bag {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 350px;
  max-width: 95vw;
  background-color: var(--light-grey);
  z-index: 50;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0;
}

.container-modal-bag .burger {
  display: block !important;
}

.modal-bag-container-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.modal-bag-svg {
  width: 20%;
  height: 20%;
  margin-bottom: 20px;
}

.modal-bag-svg path {
  fill: var(--grey);
}

.modal-bag-text-empty {
  font-family: "DIN Next LT Pro", "sans-serif";
  color: var(--grey);
  text-align: center;
  width: 80%;
}

.modal-bag-container-products {
  max-height: calc(100vh - 90px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.container-product-bag {
  background-color: var(--white);
  margin: 15px 0;
  border-bottom: 1px solid var(--border-grey);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-bag-disappear {
  animation: product-bag-disappear 1s ease-in-out both;
}

@keyframes product-bag-disappear {
  0% {
    transform: translateX(0);
    max-height: 500px;
    opacity: 1;
    box-sizing: border-box;
  }
  100% {
    transform: translateX(100%);
    margin: 0;
    max-height: 0;
    opacity: 0;
    padding: 0;
  }
}

.product-bag-container-product {
  display: flex;
  width: 100%;
}

.product-bag-container-image {
  position: relative;
  width: 160px;
  margin-right: 10px;
  background-color: var(--light-grey);
}

.product-bag-container-image::before {
  content: "";
  background-color: var(--light-grey);
  display: block;
  padding-top: 125%;
  width: 100%;
  border-radius: inherit;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.product-bag-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.product-bag-container-info {
  width: calc(100% - 180px);
}

.product-bag-name {
  color: var(--black);
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 10px;
}

.product-bag-detail {
  font-size: 13px;
  color: var(--grey);
  margin-bottom: 5px;
}

.modal-bag-bottom {
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
}

.product-bag-price {
  color: var(--black);
  font-size: 14px;
  font-weight: 800;
}

.bag-container-total {
  color: var(--black);
  font-size: 15px;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
}
