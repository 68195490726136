.container-mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  width: 400px;
  max-width: 80vw;
  background-color: var(--light-grey);
  z-index: 10;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.mobile-menu-section {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 18px 40px 20px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0ded7;
  background-color: #fcfcfa;
  font-family: "PF Din Text Universal", "sans-serif";
  color: var(--black);
  cursor: pointer;
}

.mobile-menu-section.mobile-menu-section-close {
  justify-content: flex-end;
  background-color: inherit;
  border-top-right-radius: 10px;
}

.mobile-menu-container-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.mobile-menu-icon {
  max-width: 40px;
  max-height: 60px;
}

.mobile-menu-instagram {
  font-size: 14px;
  font-weight: bold;
  color: var(--black);
  text-decoration: underline;
}

.mobile-menu-open {
  animation: mobile-menu-open 0.5s ease-in-out both;
}

@keyframes mobile-menu-open {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

.mobile-menu-close {
  animation: mobile-menu-close 0.5s ease-in-out both;
}

@keyframes mobile-menu-close {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media (max-width: 1000px) {
  .container-mobile-menu {
    display: block;
  }
}
