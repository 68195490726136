.measure-list-container-item {
  margin: 10px 0;
  display: flex;
  position: relative;
}

.measure-desc-input {
  font-weight: bold;
  color: var(--grey);
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.measure-subtitle {
  font-family: "DIN Next LT Pro", "sans-serif";
  font-size: 25px;
  color: var(--grey);
  text-transform: uppercase;
}

.measure-list-item {
  width: 50px;
  border-radius: 5px;
  padding: 2px;
}
