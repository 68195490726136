:root {
  --black: #292a33;
  --white: #fefcf5;
  --white-hover: #fefcf5;
  --grey: #494d59;
  --light-grey: #f5f4f2;
  --border-grey: #e0ded7;
  --red: #c00;
}
body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

select,
option,
input,
textarea,
body,
html,
button {
  font-family: "Source Sans Pro", sans-serif;
}

input,
textarea {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li,
ul {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@media (min-width: 1000px) {
  .mobile {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  .pc {
    display: none !important;
  }
}

.container-modal {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.container-modal-open {
  animation: container-modal-open 0.5s ease-in-out both;
}

@keyframes container-modal-open {
  0% {
    left: 0;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

.container-modal-close {
  animation: container-modal-close 0.5s ease-in-out both;
}

@keyframes container-modal-close {
  0% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 0;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  outline: none;
  font-size: 15px;
  min-height: 50px;
  padding: 3px 15px;
  margin: 5px;
  font-weight: bold;
  background: none;
  color: var(--black);
  border: 2px solid #e0ded7;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
}

.container-double-buttons {
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.container-double-buttons .button {
  margin: 0;
  width: 50%;
  box-sizing: border-box;
}

.container-double-buttons .button:last-child {
  border-radius: 0 50px 50px 0;
  border-left: none;
}

.container-double-buttons .button:first-child {
  border-radius: 50px 0 0 50px;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out both;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
