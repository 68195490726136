.container-accordion {
  background-color: var(--light-grey);
  padding: 18px 20px 20px 20px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  max-height: 60px;
  margin: 10px 0;
  cursor: pointer;
}

.container-accordion-style-modal {
  border-bottom: 1px solid #e0ded7;
  padding: 18px 32px 20px 20px;
  background-color: #fcfcfa;
  border-radius: 0px;
  margin: 0;
}

.container-accordion-more {
  animation: container-accordion-more 0.5s ease-in-out both;
}

@keyframes container-accordion-more {
  0% {
    max-height: 60px;
  }
  100% {
    max-height: 100%;
  }
}

.container-accordion-less {
  animation: container-accordion-less 0.5s ease-in-out both;
}

@keyframes container-accordion-less {
  0% {
    max-height: 100%;
  }
  100% {
    max-height: 60px;
  }
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.accordion-title {
  color: var(--black);
  font-weight: 700;
}

.accordion-desc {
  font-size: 12px;
  color: var(--grey);
}

.accordion-more {
  position: relative;
  width: 40px;
  height: 20px;
}

.accordion-more div {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 15px;
  height: 2px;
  background-color: var(--grey);
}

.accordion-more div:last-child {
  transform: rotate(-90deg);
}

.accordion-animation-less div:last-child {
  animation: accordion-less 0.5s ease-in-out both;
}

@keyframes accordion-less {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.accordion-animation-more div:last-child {
  animation: accordion-more 0.5s ease-in-out both;
}

@keyframes accordion-more {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.accordion-item-list {
  padding: 14px 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.accordion-circle {
  width: 20px;
  height: 20px;
  border: 2px solid var(--black);
  border-radius: 50%;
  margin-right: 10px;
}

.accordion-circle-select {
  width: 100%;
  height: 100%;
  background-color: var(--black);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-circle-select-content {
  width: 10px;
  height: 10px;
  background-color: var(--white);
  border-radius: 50%;
}

@media (min-width: 768px) {
  .accordion-item-list:hover .accordion-circle {
    background-color: var(--black);
    transition: 0.2s ease-in-out;
  }
}
