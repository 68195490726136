.burger {
  display: none;
  cursor: pointer;
  transition: all 0.5s linear;
  border-radius: 20%;
}

.burger div {
  width: 18px;
  height: 1px;
  background-color: var(--white);
  margin: 5px 0;
  transition: all 0.5s linear;
}

.burger-dark div {
  width: 19px;
  background-color: var(--grey);
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-2px, 7px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-2px, -7px);
}

@media (max-width: 1000px) {
  .burger {
    display: block;
  }
}
