.container-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
}

.filters-title {
  font-family: "DIN Next LT Pro", "sans-serif";
  font-size: 30px;
  color: var(--black);
}

.filters-container-lists {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.filters-subtitle {
  font-family: "DIN Next LT Pro", "sans-serif";
  font-size: 25px;
  color: var(--grey);
}

.filters-description {
  font-size: 14px;
}

.filters-container-list {
  margin: 20px;
}

.filters-list-container-item {
  position: relative;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.filters-list-item {
  border-radius: 10px;
  border: 1px solid var(--grey);
  margin: 5px 0;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
}

.filter-list-item-x {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: var(--grey);
  color: var(--white);
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}

.filter-list-button {
  width: 100%;
  margin: 10px 0;
  min-height: 35px;
}
