.container-list-products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stuffed-scroll {
  width: 100%;
  height: 5000px;
}

.container-list-product {
  width: 24%;
  min-width: 150px;
  margin-bottom: 55px;
  cursor: pointer;
}

.list-product-container-image {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  background-color: var(--light-grey);
}

.list-product-container-image::before {
  content: "";
  background-color: var(--light-grey);
  display: block;
  padding-top: 125%;
  width: 100%;
  border-radius: inherit;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.list-product-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.list-product-name {
  color: var(--grey);
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 10px;
}

.list-product-price {
  color: var(--black);
  font-size: 18px;
  font-weight: 800;
}

@media (max-width: 1200px) {
  .container-list-product {
    width: 32%;
  }
}

@media (max-width: 800px) {
  .container-list-product {
    width: 49%;
  }
}
