.container-shop {
  display: flex;
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .container-shop {
    flex-direction: column;
  }
}
