.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-google {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.button-google {
  width: 300px;
  max-width: 80vw;
}
