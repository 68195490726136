.footer {
  background-color: var(--light-grey);
  font-size: 13px;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 0;
}

.footer-drop-icon {
  max-width: 50px;
  max-height: 50px;
}

.footer-copyright {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
}

.footer a {
  color: var(--black);
  font-weight: 700;
  text-decoration: underline;
}

.footer-container-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-socials-text {
  font-size: 13px;
  color: var(--grey);
  margin-bottom: 10px;
  display: none;
}

.footer-socials-container-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .item {
  margin: 0 30px;
}
