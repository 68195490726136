.container-product {
  margin: 0 auto;
  display: flex;
  width: 1200px;
}

.product-container-carousel {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.product-container-info {
  width: 340px;
  margin-left: 20px;
  min-width: 300px;
}

.product-name {
  font-family: "DIN Next LT Pro", "sans-serif";
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 5px;
}

.product-name.only-mobile {
  display: none;
  font-size: 22px;
  margin-bottom: 20px;
}

.product-price {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 50px;
}

.product-hr {
  border: none;
  height: 1px;
  background-color: #e3ded1;
  margin-bottom: 20px;
}

.product-info-container-sizes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
}

.product-info-size {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  margin: 3px;
}

.product-info-size:hover,
.product-info-size-select {
  background-color: var(--grey);
  color: var(--white);
  cursor: pointer;
}

.product-info-size-disabled,
.product-info-size-disabled:hover {
  color: rgba(41, 42, 51, 0.5);
  background-color: var(--light-grey);
  position: relative;
  overflow: hidden;
  cursor: default;
}

.product-info-size-disabled::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    to top right,
    transparent calc(50% - 0.5px),
    #e0ded7 calc(50% - 0.5px),
    #e0ded7 calc(50% + 0.5px),
    transparent calc(50% + 0.5px)
  );
}

.product-info-subtitle {
  font-size: 13px;
  font-weight: 700;
}

.product-info-container-details {
  background-color: #f6f6f6;
  padding: 30px 30px 40px;
  box-sizing: border-box;
  width: 100%;
}

.product-info-details-title {
  font-family: "DIN Next LT Pro", "sans-serif";
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  color: var(--black);
  margin-bottom: 5px;
}

.product-info-details-subtitle {
  text-align: center;
  font-size: 13px;
  margin-bottom: 15px;
}

.product-info-details-detail {
  font-size: 13px;
}

.product-info-container-add-to-cart {
  margin-top: 25px;
}

@media (max-width: 1000px) {
  .product-container-carousel::before {
    content: "";
    display: block;
    padding-top: 700px;
    padding-bottom: 120px;
    width: 100%;
    border-radius: inherit;
    position: relative;
  }

  .image-gallery-content {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
  }
  .product-container-info {
    min-width: 230px;
  }
}

@media (max-width: 764px) {
  .product-container-carousel::before {
    padding-top: 500px;
  }
  .container-product {
    flex-direction: column;
  }
  .product-name {
    display: none;
  }
  .product-name.only-mobile {
    display: block;
    text-align: center;
  }
  .product-container-info {
    width: 100%;
    margin: 0;
    min-width: 0;
  }
  .image-gallery-left-nav {
    display: none;
  }
  .image-gallery-right-nav {
    display: none;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 500px;
  }

  .image-gallery-thumbnail-image {
    height: 100px;
    object-fit: cover;
  }
}

@media (max-width: 540px) {
  .product-container-carousel::before {
    padding-top: 500px;
  }
  .image-gallery-slide > div {
    width: 100%;
    padding-top: 125%;
    border-radius: inherit;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      #eeeeee 10%,
      #dddddd 18%,
      #eeeeee 33%
    );
    background-size: 800px 104px;
    position: relative;
  }

  .image-gallery-slide > div > img {
    position: absolute;
    top: 0;
    left: 0;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 500px;
  }

  .image-gallery-thumbnail-image {
    height: 100px;
    object-fit: cover;
  }
}

.image-gallery-bullets .image-gallery-bullet:focus,
.image-gallery-bullets .image-gallery-bullet:hover {
  background-color: var(--grey) !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 40px !important;
  height: 80px !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 80vh !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background-color: var(--grey) !important;
}

.image-gallery-bullets .image-gallery-bullet {
  box-shadow: none !important;
  border: 1px solid var(--black) !important;
  width: 8px !important;
  height: 8px !important;
}

.table-measures {
  margin: 0 auto;
  border-spacing: 1px;
}

.table-measures td,
.table-measures th {
  border: 1px solid var(--grey);
  padding: 5px;
  margin: 0;
}
.table-measures th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--black);
  color: var(--white);
}

.container-measures {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.measures-text {
  margin-bottom: 10px;
}
